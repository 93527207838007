import { graphql } from "gatsby"
import React from "react"
import Img from "gatsby-image"
import Layout from "./layout"
import AgenciesMarquee from "./agencies-marquee"
import SEO from "../components/seo"
import RequestDemoSection from "../components/request-demo-section"
import CaseStudiesSection from "./case-studies-section"

function slugToTitle(slug) {
  return slug
    .split("-")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export default ({ data }) => {
  const post = data.wordpressAcfCaseStudies.acf
  return (
    <Layout>
      <SEO
        title={post.title + " | Case Study"}
        image={post.hero?.localFile.childImageSharp.fluid.src}
        pageType="article"
      />

      <div className="px-4">
        <div className="container mt-6">
          <p className="is-size-5">{slugToTitle(post.slug)}</p>
          <h1
            className="
            is-size-1-desktop
            is-size-3
            has-text-weight-medium
            has-text-dark
            mb-5
          "
            style={{ lineHeight: "1.2" }}
          >
            {post.title}
          </h1>
          <div className="case-study-hero-image">
            <Img
              fluid={post.hero.localFile.childImageSharp.fluid}
              style={{ width: "100%" }}
            />
            <div className="case-study-hero-image--logo">
              <Img fluid={post.logo.localFile.childImageSharp.fluid} />
            </div>
          </div>
          <div className="case-studies-results">
            <div>
              <p className="is-size-6 is-size-7-mobile has-text-dark">
                {post.label_1}
              </p>
              <p className="is-size-4 has-text-dark has-text-weight-bold">
                {post.text_1}
              </p>
              <p className="is-size-6 is-size-7-mobile has-text-dark">
                {post.label_1_bottom}
              </p>
            </div>
            <div className="case-study-card--results__divider"></div>
            <div>
              <p className="is-size-6 is-size-7-mobile has-text-dark">
                {post.label_2}
              </p>
              <p className="is-size-4 has-text-dark has-text-weight-bold">
                {post.text_2}
              </p>
              <p className="is-size-6 is-size-7-mobile has-text-dark">
                {post.label_2_bottom}
              </p>
            </div>
            <div className="case-study-card--results__divider"></div>
            <div>
              <p className="is-size-6 is-size-7-mobile has-text-dark">
                {post.label_3}
              </p>
              <p className="is-size-4 has-text-dark has-text-weight-bold">
                {post.text_3}
              </p>
              <p className="is-size-6 is-size-7-mobile has-text-dark">
                {post.label_3_bottom}
              </p>
            </div>
          </div>
        </div>
        <div
          className="case-study blog-post-container has-text-dark"
          dangerouslySetInnerHTML={{ __html: post.content }}
        ></div>
        <div className="container">
          <h2
            className="
            is-size-2
            has-text-weight-medium
            has-text-dark
            mt-6
            mb-4
            "
          >
            More Case Studies
          </h2>
          <CaseStudiesSection limit={2} ignoreSlugs={[post.slug]} />
        </div>
      </div>

      <RequestDemoSection formPlacement={"Case Study: " + post.slug} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String) {
    wordpressAcfCaseStudies(acf: { slug: { eq: $slug } }) {
      acf {
        content
        label_1
        label_1_bottom
        label_2
        label_2_bottom
        label_3
        label_3_bottom
        slug
        summary
        text_1
        text_2
        text_3
        title
        logo {
          localFile {
            childImageSharp {
              fluid(
                maxHeight: 80
                maxWidth: 80
                quality: 100
                cropFocus: CENTER
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        hero {
          localFile {
            childImageSharp {
              fluid(maxHeight: 500, maxWidth: 1256, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
